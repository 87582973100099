<div class="container-fluid" (click)="onClick();">
    <div class="row" style="margin: 5px;">
        <div class="col-lg-2 col-md-2 col-sm-2 col-12">
            <a routerLink="/overview" id="logo" class="link" title="Nebular">
                <div class="homeLink">Nebular</div>
                <span id="logoSubtitle" style="font-size: .8em">Stellar charts</span>
            </a>
        </div>
        <div class="col-lg-10 col-md-10 col-sm-10 col-12 headerLinks">
            <nav id="tools_nav_wrap">
                <a class="link top-menu-link" id="toolsLink">Tools</a>
                <ul>
                    <li><a routerLink="/liveTrades" class="link">Live Trades</a></li>
                    <li><a routerLink="/analyze" class="link">Exchange Analytics</a></li>
                </ul>
            </nav>
            <a routerLink="/configuration" class="link top-menu-link" id="configurationLink">Configuration</a>
            <a routerLink="/myExchanges" class="link top-menu-link" id="myExchangesLink">My Exchanges</a>
        </div>
    </div>

    <router-outlet></router-outlet>

</div>

<br/><br/>
<footer id="footer">
    <div class="container">
        <span>Only for information. Accuracy of data is not guaranteed. No investment or trading decision should be made based on this page.<br/>
              Got something to say? Send me a MEMO to GAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAWHF. v24.10.30</span>
    </div>
</footer>
